<!-- V1.0 base version updated by Raja Mohamed on 19-Oct-20
-->
<template>
  <v-container>
    <Snackbar :snackbar="snackbar" :snackbartext="snackbartext" @setflasesnackbar="snackbar = false"> </Snackbar>
    <Tabs :tabs="PDPOptions" @settabvalue="setPDP" />

    <v-data-table
      :headers="getheaders"
      :items="getPDP"
      :search="search"
      :loading="notloaded"
      loading-text="Loading..."
      sort-by="name"
      item-key="uid"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      :show-expand="getexpandflag"
      class="elevation-1"
      :footer-props="{
        showFirstLastPage: true,
        prevIcon: 'mdi-arrow-left',
        nextIcon: 'mdi-arrow-right',
      }"
      @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
      :items-per-page="roles.itemsPerPage"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>PDP</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
        </v-toolbar>
      </template>

      <template v-if="currentOption != 'Reports'" v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-data-table
            :headers="headerssub"
            :items="item.pdp"
            :loading="notloaded"
            loading-text="Loading..."
            sort-by="name"
            item-key="year"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            :show-expand="expandflag"
            class="elevation-1"
            :footer-props="{
              showFirstLastPage: true,
              prevIcon: 'mdi-arrow-left',
              nextIcon: 'mdi-arrow-right',
            }"
            @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
            :items-per-page="roles.itemsPerPage"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-dialog v-model="dialog" max-width="1000px">
                  <v-form ref="form" v-model="valid" :lazy-validation="lazy">
                    <v-card>
                      <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <Alertmsg v-if="alertflag" :alertmsg="alertmsg" @setflasealert="alertflag = false">
                          </Alertmsg>
                        
                          <v-row>
                            <v-col cols="12" xs="12" sm="12" md="1" lg="1">
                              <div>
                                <h4>Year</h4>
                                {{ editedItem.year }}
                              </div>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="2" lg="2">
                              <div>
                                <h4>EmpID</h4>
                                {{ editedItem.empid }}
                              </div>
                            </v-col>

                            <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                              <div>
                                <h4>Name</h4>
                                {{ editedItem.name }}
                              </div>
                            </v-col>

                            <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                              <div>
                                <h4>Email</h4>
                                {{ editedItem.email }}
                              </div>
                            </v-col>

                            <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                              <v-combobox
                                v-model="editedItem.revieweremail"
                                label="Reviewer email"
                                :items="reviewersemails"
                              >
                              </v-combobox>
                            </v-col>

                            <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                              <TtVuetify v-if="tTFlag"
                                :tiptabheader="'Objectives'"
                                :editflag="geteditflag"
                                :tiptablable="'Objectives'"
                                :tiptabstring="editedItem.objectives"
                                @assigntiptabvalues="editedItem.objectives = $event"
                              >
                              </TtVuetify>
                            </v-col>

                            <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                              <div><h2>H1 Reviews</h2></div>
                            </v-col>
                           
                            <v-col cols="12" xs="4" sm="4" md="4" lg="4">
                              <TtVuetify v-if="tTFlag"
                                :tiptabheader="'Positives'"
                                :editflag="geteditflag"
                                :tiptablable="'H1Positives'"
                                :tiptabstring="editedItem.h1positives"
                                @assigntiptabvalues="editedItem.h1positives = $event"
                              >
                                </TtVuetify>
                            </v-col>
                           
                            <v-col cols="12" xs="4" sm="4" md="4" lg="4">
                              <TtVuetify v-if="tTFlag"
                                :tiptabheader="'Improvements'"
                                :editflag="geteditflag"
                                :tiptablable="'H1Improvements'"
                                :tiptabstring="editedItem.h1improvements"
                                @assigntiptabvalues="editedItem.h1improvements = $event"
                              >
                                </TtVuetify>
                            </v-col>
                          
                            <v-col cols="12" xs="4" sm="4" md="4" lg="4">
                              <TtVuetify v-if="tTFlag"
                                :tiptabheader="'Comments'"
                                :editflag="geteditflag"
                                :tiptablable="'H1Comments'"
                                :tiptabstring="editedItem.h1comments"
                                @assigntiptabvalues="editedItem.h1comments = $event"
                              >
                                </TtVuetify>
                            </v-col>

                            <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                              <div><h2>H2 Reviews</h2></div>
                            </v-col>

                            <v-col cols="12" xs="4" sm="4" md="4" lg="4">
                              <TtVuetify v-if="tTFlag"
                                :tiptabheader="'Positives'"
                                :editflag="geteditflag"
                                :tiptablable="'H2Positives'"
                                :tiptabstring="editedItem.h2positives"
                                @assigntiptabvalues="editedItem.h2positives = $event"
                              >
                                </TtVuetify>
                            </v-col>
                            <v-col cols="12" xs="4" sm="4" md="4" lg="4">
                              <TtVuetify v-if="tTFlag"
                                :tiptabheader="'Improvements'"
                                :editflag="geteditflag"
                                :tiptablable="'H2Improvements'"
                                :tiptabstring="editedItem.h2improvements"
                                @assigntiptabvalues="editedItem.h2improvements = $event"
                              >
                                </TtVuetify>
                            </v-col>

                            <v-col cols="12" xs="4" sm="4" md="4" lg="4">
                              <TtVuetify v-if="tTFlag"
                                :tiptabheader="'Comments'"
                                :editflag="geteditflag"
                                :tiptablable="'H2Comments'"
                                :tiptabstring="editedItem.h2comments"
                                @assigntiptabvalues="editedItem.h2comments = $event"
                              >
                                </TtVuetify>
                            </v-col>

                            <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                              <TtVuetify v-if="tTFlag"
                                :tiptabheader="'Next Year Objectives'"
                                :editflag="geteditflag"
                                :tiptablable="'Next Year Objectives'"
                                :tiptabstring="editedItem.nextyearobjectives"
                                @assigntiptabvalues="editedItem.nextyearobjectives = $event"
                              >
                                </TtVuetify>
                            </v-col>

                            <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                              <TtVuetify v-if="tTFlag"
                                :tiptabheader="'Addtional Comments'"
                                :editflag="geteditflag"
                                :tiptablable="'Addtional Comments'"
                                :tiptabstring="editedItem.addtionalcomments"
                                @assigntiptabvalues="editedItem.addtionalcomments = $event"
                              >
                                </TtVuetify>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                        <v-btn
                          color="blue darken-1"
                          v-if="geteditflag && !spinner"
                          text
                          :disabled="!valid"
                          @click="saveitem"
                          >Save</v-btn
                        >
                        <Spinner v-if="spinner" />
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click.stop="editItem(item)"> {{getediticon(item)}} </v-icon>
              <!-- <v-icon  small @click="deleteItem(item)" > mdi-delete </v-icon> -->
            </template>
          </v-data-table>
        </td>
      </template>

      <template v-slot:no-data>
        <div><h2 class="blue">No data Found- Please add PDP</h2></div>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { PDPRef, reviewersRef, empRef, authdomain } from "@/fb"
import { formMixin } from "@/mixins/formMixin.js"
import { emailMixin } from "@/mixins/emailMixin.js"
import { convertAll } from "@/mixins/convertall.js"
import Snackbar from "@/components/common/snackbar.vue"
import Alertmsg from "@/components/common/Alertmsg.vue"
import Spinner from "@/components/common/spinner.vue"
import Tabs from "@/components/common/Tabs.vue"
import TtVuetify from "@/components/common/TtVuetify.vue"
import { collectionsOperation } from "@/mixins/collectionsOperations.js"
import { json2excel } from "js2excel"
import {mapState} from "vuex"

export default {
  name: "PDPreview",
  //props:['roles'],
  mixins: [formMixin, emailMixin, convertAll, collectionsOperation], // for confirmation while delete
  components: { Snackbar, Alertmsg, Spinner, Tabs, TtVuetify },
  data: () => ({
    dialog: false,
    valid: true,
    lazy: false,
    search: "", // for data table search
    domain: "",
    notloaded: true, // will be made false once the data-table items fetched from db. till that time loading please wait message will be shown to the user
    singleExpand: false,
    expanded: [],
    expandflag: true,
    headersself: [
      {
        text: "EMPID",
        align: "start",
        sortable: true,
        value: "empid",
      },
      { text: "Name", value: "name" },
      { text: "EMail", value: "email" },
      { text: "Reviewer", value: "reviewername" },
      { text: "Reviewer Email", value: "revieweremail" },
    ],

    headerssub: [
      {
        text: "Year",
        align: "start",
        sortable: true,
        value: "year",
      },
      { text: "status", value: "status" },

      { text: "Reviewer", value: "reviewername" },
      { text: "Reviewer Email", value: "revieweremail" },
      { text: "Review Status", value: "reviewerstatus" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    headersyear: [
      {
        text: "Year",
        align: "start",
        sortable: true,
        value: "year",
      },
      { text: "Name", value: "name" },
      { text: "EMail", value: "email" },

      { text: "Reviewer", value: "reviewername" },
      { text: "Reviewer Email", value: "revieweremail" },
      { text: "Review Status", value: "reviewerstatus" },
    ],

    inputrequired: [(v) => !!v || "Input is required"],
    PDPyears: [],
    editedIndex: -1,
    editedItem: {
      objectives: "",
      positives: "",
      improvements: "",
      comments: "",
      halfyearly: "",
      nextyearobjectives: "",
      year: "",
      reviewername: "",
      revieweremail: "",
      reviewerid: "",
      status: "",
      empid: "",
      name: "",
      email: "",
    },
    defaultItem: {},
    PDPOptions: ["Self"],

    alertflag: false,
    alertmsg: "",
    snackbartext: "",
    snackbar: false,
    email: "",
    currentOption: "",
    spinner: false,
    roles: "",
    reviewers: [],
    reviewersemails: [],
    allPDP: [],
    tTFlag:false,
  }), // end of data

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  async created() {
    this.roles = await this.$store.state.userProfile
    console.log("PDP review", this.roles)
    if (this.roles) {
      if (this.roles.role.hradmin || this.roles.role.employee || this.roles.role.readuser) {
        this.domain = this.roles.domain
        this.email = this.roles.email
        this.defaultItem = Object.assign({}, this.editedItem)
        this.initialize()
      } else {
        alert("Not authorized to access this page , kindly contact support")
        //this.$router.replace({ name: this.roles.menufrom})
        this.$router.push({ name: this.roles.menufrom, params: { roles: this.roles } })
      }
    } else {
      alert("Not authorized to access this page , kindly contact support")
      this.$router.replace({ name: "/login" })
    }
  },

  mounted() {
    // the below will monitor the main PDP table and update the profile array.
    if(this.hradminrole || this.readuserrole ){
      PDPRef.where("domain", "==", this.currentDomain).onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
        
            if(!this.notloaded){
           // console.log("inside mounted",change)
            this.updaterealtimedata(this.allPDP, change)
          
          } //end of this.domain*/
        }) // end of for each
      }) // end of snapshot
    }
    else{
      PDPRef.where("domain", "==", this.currentDomain).where("email", "==", this.currentemail).onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
        
            if(!this.notloaded){
            console.log("inside mounted",change)
            this.updaterealtimedata(this.allPDP, change)
          
          } //end of this.domain*/
        }) // end of for each
      }) // end of snapshot
    }
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Edit PDP" : "Edit PDP"
    },
    getexpandflag() {
      return this.currentOption == "Reports" ? false : true
    },
    getheaders() {
      return this.currentOption == "Reports" ? this.headersyear : this.headersself
    }, // end of getheaders
    geteditflag() {
      return this.editedItem.email == this.email ? false : true
    },
    
    ...mapState({
      currentDomain: state=> state.userProfile.domain,
      currentemail:  state=>state.userProfile.email,
      hradminrole: state=> state.userProfile.role.hradmin,
      readuserrole: state=> state.userProfile.role.readuser,
    }),

    getPDP: function () {
      var pdparray = []
  
      switch (this.currentOption) {
        case "Self":
          pdparray = this.allPDP.filter((re) => re.email == this.email)
          break
        case "Team":
          pdparray = this.allPDP.filter((re) => re.revieweremail == this.email)
          break
        case "All":
          pdparray = JSON.parse(JSON.stringify(this.allPDP))
          break
        case "Reports":
          pdparray = this.PDPyears
         
          break

          case "Export":
         
          this.reportExport()
          break
        default:
          pdparray = this.allPDP.filter((re) => re.email == this.email)
          break
      }
      return pdparray
    }, // end of getpdp
  }, //end of computed 

  methods: {

    async reportExport(){
   console.log("inside reportExport")
    //  var reportsarray=[];
    //  for(var i=0;i<this.allemps.length;++i){
    //    var emp=this.allemps[i];
    //    var officialDetails=emp.officialDetails;
    //    var personalDetails=emp.personalDetails;
    //    var familyDetails=emp.familyDetails;
    //     var documentDetails=emp.documentDetails;
    //    var emergencyDetails=emp.emergencyDetails;
    //    await reportsarray.push({ 
    //       EmpId: officialDetails.empid,
    //       Email:officialDetails.email,
    //       Name: officialDetails.name,
    //       Manager_name: officialDetails.mgrname,
    //       Manager_Email: officialDetails.mgremail,
    //       //mgrid: officialDetails.mgrid,
    //       Cient_manager_Name: officialDetails.clientmgrname,
    //       Client_manager_Email: officialDetails.clientmgremail,
    //       //clientmgrid: officialDetails.clientmgrid,
    //       DOJ: officialDetails.empDOJ,
    //       DOE: officialDetails.empDOE,
    //       DOR: officialDetails.empDOR,
    //       Department: officialDetails.deptName,
    //       Grade: officialDetails.grade,
    //       Designation: officialDetails.designation,
    //       Client: officialDetails.client,
    //       Onsite: officialDetails.onsite,
    //       Onsite_Country: officialDetails.onsitecountry,
          
    //       Personal_Email:personalDetails.email,
    //       FullName:personalDetails.fullName,
    //       MobileNumber:personalDetails.mobileNumber,
    //       Gender:personalDetails.gender,
    //       DOB:personalDetails.empDOB,
    //       CurrentAddress:personalDetails.currentAddress,
    //       AlternateContact:personalDetails.alternateContact,
    //       PermanentAddress:personalDetails.permanentAddress,
    //       MatrialStatus:personalDetails.matrialStatus,
    //       BloodGroup:personalDetails.bloodGroup,
    //       WeddingAnniversary:personalDetails.weddingAnniversary,
    //       FatherName:familyDetails.father.name,
    //       FatherDOB:familyDetails.father.DOfB,
    //       MotherName:familyDetails.mother.name,
    //       MotherDOB:familyDetails.mother.DOfB,
    //       SpouseName:familyDetails.spouse.name,
    //       SpouseDOB:familyDetails.spouse.DOfB,
    //       Child1Name:familyDetails.child1.name,
    //       Child1DOB:familyDetails.child1.DOfB,
    //       Child1gender:familyDetails.child1.gender,
    //       Child2Name:familyDetails.child2.name,
    //       Child2DOB:familyDetails.child2.DOfB,
    //       Child2gender:familyDetails.child2.gender,
    //       PassportNumber:documentDetails.passportNumber,
    //       PassportExpiryDate:documentDetails.passportExpiryDate,
    //       AddharNumber:documentDetails.addharNumber,
    //       PanNumber:documentDetails.panNumber,
    //       PFUAN:documentDetails.pfUAN,
    //       EmergencycontactName:emergencyDetails.contactName,
    //       EmergencycontactNumber:emergencyDetails.contactNumber,
    //       EmergencycontactRelation:emergencyDetails.contactRelation,
    //       Emergencyalternatecontact:emergencyDetails.alternateContact,




          // doj:officialDetails.empDOJ,
          // Birthday:this.convertDate(emprecords.docs[i].data().personalDetails.empDOB),
          // Wedding:this.convertDate(emprecords.docs[i].data().personalDetails.weddingAnniversary),
          // spouseDOB:this.convertDate(emprecords.docs[i].data().familyDetails.spouse.DOfB),
          // child1DOB:this.convertDate(emprecords.docs[i].data().familyDetails.child1.DOfB),
          // child2DOB:this.convertDate(emprecords.docs[i].data().familyDetails.child2.DOfB),
          // fatherDOB:this.convertDate(emprecords.docs[i].data().familyDetails.father.DOfB),
          // motherDOB:this.convertDate(emprecords.docs[i].data().familyDetails.mother.DOfB),
          // CurrentAddress:emprecords.docs[i].data().personalDetails.currentAddress,
          // PermanentAddress:emprecords.docs[i].data().personalDetails.permanentAddress,

          // })

    //  }
    var pdparray = JSON.parse(JSON.stringify(this.allPDP))
    console.log(pdparray)
    var tempPDP=pdparray.map((el)=>{console.log(el) 
      return el.pdp.filter((e2)=> e2.year=="2024")[0]})
    console.log("tempPDP",tempPDP)
    var tempPDP1 = tempPDP.filter(item => { return item !== undefined });
    console.log("tempPDP1",tempPDP1)
    var data = tempPDP1 //JSON.parse(JSON.stringify(tempPDP))
     try {
        json2excel({
          data,
          name: "PDPDetails",
          formateDate: "yyyy/mm/dd",
        })
      } catch (e) {
        console.error("export error", e)
      }

    
     

    },

     getediticon(item) {
      // console.log("getediticon", this.headersself.email,this.email,item)
      return item.email == this.email ? "mdi-eye" : "mdi-pencil"
    },
    setPDP(PDPOption) {
      this.currentOption = PDPOption
      //this.currentOption="Export"
    },

    /* assigntiptabvalues(option, value) {
      switch (option) {
        case "Objectives":
          this.editedItem.objectives = value
          break
        case "Addtional Comments":
          this.editedItem.addtionalcomments = value
          break
        case "Next Year Objectives":
          this.editedItem.nextyearobjectives = value
          break
        case "H2Comments":
          this.editedItem.h2comments = value
          break
        case "H2Improvements":
          this.editedItem.h2improvements = value
          break
        case "H2Positives":
          this.editedItem.h2positives = value
          break
        case "H1Comments":
          this.editedItem.h1comments = value
          break
        case "H1Improvements":
          this.editedItem.h1improvements = value
          break
        case "H1Positives":
          this.editedItem.h1positives = value
          break
      }
    },*/

    async initialize() {
      this.allPDP = []
      var ret = await this.loadInitialData(PDPRef, this.domain, this.allPDP)
      if (ret != true) {
        this.alertflag = true
        this.alertmsg = ret
        this.notloaded = false
      }
      if (this.allPDP.filter((re) => re.revieweremail == this.email).length > 0) {
        this.PDPOptions.push("Team")
      }
      if (this.roles.role.hradmin || this.roles.role.readuser) {
        this.PDPOptions.push("All")
        this.PDPOptions.push("Export")

        // below will form an array for each year and
        this.reviewers = []
        this.reviewersemails = []
        await reviewersRef
          .doc(this.domain)
          .get()
          .then((doc) => {
            if (doc.exists) {
              this.reviewers = doc.data().reviewers
              this.reviewersemails = this.reviewers.map((a) => a.email)
            }
          })

        this.PDPyears = []
        for (var i = 0; i < this.allPDP.length; i++) {
          for (var j = 0; j < this.allPDP[i].pdp.length; j++) {
            this.PDPyears.push({ ...this.allPDP[i].pdp[j] })
          }
        }
        this.PDPOptions.push("Reports")
      }
      this.currentOption = "Self"
      this.notloaded = false
    },

    editItem(item) {
      console.log("inside item", item)
      this.tTFlag=true
      this.editedIndex = this.allPDP.findIndex((obj) => obj.email == item.email)
       this.editedItem={};
      this.editedItem = Object.assign({}, item)
      console.log("inside editItem",this.editedIndex,this.editedItem)
      this.dialog = true
      console.log("inside edit item year", this.editedItem.year)
    },

    close() {
      this.dialog = false
      this.spinner = false
      this.tTFlag=false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async saveitem() {
      try {
        this.spinner = true
        this.tTFlag=false
        var empdata = this.allPDP.filter((re) => re.email == this.editedItem.email)
        var emppdparray = empdata[0].pdp
        var idx = emppdparray.findIndex((obj) => obj.year == this.editedItem.year)

        // below will check the manager mail id change ,if it is then assign requires values to the document.
        var currentrevieweremail = empdata[0].pdp[idx].revieweremail
        empdata[0].pdp[idx] = Object.assign({}, this.editedItem)
        console.log("currentrevieweremail", this.editedItem.revieweremail, currentrevieweremail)
        if (this.editedItem.revieweremail != currentrevieweremail) {
          console.log(
            "inside manager name change",
            this.editedItem.revieweremail,
            currentrevieweremail,
            this.editedItem.email
          )
          //get the reviewer name and id from array
          var ridx = this.reviewers.findIndex((obj) => obj.email == this.editedItem.revieweremail)
          if (ridx > -1) {
            console.log("new reviewers details", this.reviewers[ridx])
            this.editedItem.reviewername = this.reviewers[ridx].name
            this.editedItem.reviewerid = this.reviewers[ridx].id

            console.log("after assignment new reviewer details", this.editedItem)
            // find the idx of year in pdp array and assign the reviewer details
            ridx = empdata[0].pdp.findIndex((obj) => obj.year == this.editedItem.year)

            empdata[0].pdp[ridx].revieweremail = this.editedItem.revieweremail
            empdata[0].pdp[ridx].reviewername = this.editedItem.reviewername
            empdata[0].pdp[ridx].reviewerid = this.editedItem.reviewerid

            var pdppageid = this.editedItem.reviewerid + "-" + this.editedItem.empid
            empdata[0].pdp[ridx].pdppageid = pdppageid

            console.log(
              "before update in pdp-manager name change",
              this.editedItem.revieweremail,
              this.reviewers[ridx].name,
              this.reviewers[ridx].id
            )

            // update the pdp collections
            await PDPRef.doc(this.editedItem.email).update({
              pdp: empdata[0].pdp,
              pdppageid: pdppageid,
              revieweremail: this.editedItem.revieweremail,
              reviewername: this.editedItem.reviewername,
              reviewerid: this.editedItem.reviewerid,
            })
            // update reviewers details in emp collection
            var queryresult = await empRef.where("email", "==", this.editedItem.email).get()
            if (queryresult.docs.length > 0) {
              var tmpuid = queryresult.docs[0].id
              var officialDetails = Object.assign({}, queryresult.docs[0].data().officialDetails)

              officialDetails.clientmgremail = this.editedItem.revieweremail
              officialDetails.clientmgrid = this.editedItem.reviewerid
              officialDetails.clientmgrname = this.editedItem.reviewername

              empRef.doc(tmpuid).update({
                officialDetails: officialDetails,
              })
            } // end of ueryresult.docs.length>0
            this.callmail(
              this.editedItem.email,
              this.editedItem.revieweremail,
              this.editedItem.reviewername,
              this.editedItem.year,
              this.editedItem.status
            )
          } // end idx >-1 for reviewer list.
          else {
            this.alertflag = true
            this.alertmsg = "Reviewer details not found please update the same"
            this.spinner = false
          }
        } // change in manageremail
        else {
          // console.log("inside update item empdata[0].pdp",empdata[0].pdp)
          await PDPRef.doc(this.editedItem.email).update({
            pdp: empdata[0].pdp,
          })
        } // else of no change in manager email

        Object.assign(this.allPDP[this.editedIndex].pdp[idx], this.editedItem)

        this.snackbar = true
        this.snackbartext = "PDP updated successfully"
        this.spinner = false
        this.close()
      } catch (err) {
        // end of try
        this.alertflag = true
        this.alertmsg = "Error in update PDP :" + err
        this.spinner = false
      }
    }, //end of saveitem

    async callmail(newemail, revieweremail, reviewername, year,status) {
      var queryresult = await PDPRef.where("revieweremail", "==", revieweremail).get()
      var maildetails = []
      console.log("inside callmail", newemail, revieweremail, queryresult.docs.length)
      for (var i = 0; i < queryresult.docs.length; i++) {
        var newflag = false
        if (queryresult.docs[i].data().email == newemail) {
          newflag = true
        }
        var pdp = queryresult.docs[i].data().pdp
        var idx = pdp.findIndex((obj) => obj.year == year)
        if (pdp[idx].reviewerstatus == "Open") {
          maildetails.push({
            name: queryresult.docs[i].data().name,
            reviewpageid: queryresult.docs[i].data().pdppageid,
            newflag: newflag,
          })
        }
      }
      var mailmsg = { to: "", subject: "", message: "" }
      mailmsg.to = revieweremail
      mailmsg.message = "Hi " + reviewername + "," + "<br>" + "<br>"

      console.log("this.status",status)
       if (status == "Half Yearly Open"){
    
          mailmsg.subject = "Mid-year "
          mailmsg.message += "Mid-Year "
    
        }  else if(status == "Year End Open"){
           
             mailmsg.subject = "Year-end "
            
        }

      mailmsg.subject += "Performance Review of Venzo Employees-New Member"
      
      mailmsg.message +=
        "Performance Review Process for the year " +
        year +
        " has been initiated for Venzo Employees. Kindly Click the link against each employee and fill in your review comments." +
        "<br>" +
        "<br>"
      mailmsg.message +=
        "You will receive a weekly reminder (similar email as this one) until completion of the review." +
        "<br>" +
        "<br>"

        mailmsg.message +=
        "Some of the employee(s) may have started to work with us only this year, and request you to update the Objectives for 2021 as well." +
        "<br>" +
        "<br>"

      mailmsg.message += "Performance Review is Pending For" + "<br>" + "<br>"
      for (i = 0; i < maildetails.length; ++i) {
        var pagelink = authdomain + "reviewpdp/" + maildetails[i].reviewpageid
        mailmsg.message += maildetails[i].name + " - "
        mailmsg.message += '<a href="' + pagelink + '">Click here</a>'
        if (maildetails[i].newflag == true) mailmsg.message += " (Newly added due to a change in Reporting Manager)"
        mailmsg.message += "<br>"
      }
      mailmsg.message += "<br>"
      mailmsg.message += "<br>"
      mailmsg.message +=
        "If any of the above venzo Employee(s) not reporting to you, send email to Sagit.Ramati@surecomp.com and Asif.Aly@surecomp.com"
      mailmsg.message += "<br>"
      mailmsg.message += "<br>"
      mailmsg.message += "Note: Please Open the link in Chrome"
      mailmsg.message += "<br>"
      mailmsg.message += "<br>"
      mailmsg.message += "Regards," + "<br>" + "Venzo HR Team"
      //this.sendEmail(mailmsg)
    },
  }, // end of methods
}
</script>
