<template>
  <div>
    <div>
      <h4>{{ tiptabheader }}</h4>
    </div>
    <tiptap-vuetify
      v-if="editflag"
      max-height="250px"
      v-model="tiptabvalue"
      :extensions="extensions"
      @input="assigntiptabvalues"
      :card-props="{ outlined: true }"
    />
    <v-card v-else outlined min-height="250" color="grey lighten-4">
      <v-card-text class="subtitle-1" v-html="tiptabvalue"></v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  TiptapVuetify,
  Bold,
  Italic,
  Strike,
  Underline,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  HardBreak,
  History,
} from "tiptap-vuetify"

export default {
  name: "TtVuetify",

  components: { TiptapVuetify },
  props: {
    tiptabstring: {
      type: String,
    },
    tiptablable: {
      type: String,
    },
    editflag: {
      type: Boolean,
      default: true,
    },
    tiptabheader: {
      type: String,
    },
  },
  data() {
    return {
      tiptabvalue: "",
      extensions: [
        History,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        Bold,
        Paragraph,
        HardBreak,
      ],
    } // end of retrun
  }, //end of data

  created() {
    console.log("Created-tipTapVuetify", this.tiptabstring,this.tiptablable,this.editflag)
    this.tiptabvalue = this.tiptabstring
  },
  methods: {
    assigntiptabvalues() {
      this.$emit("assigntiptabvalues", this.tiptabvalue)
    },
  },
}
</script>
