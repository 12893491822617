<template>
  <div>
    <v-row class="flex" centre>
      <v-toolbar color="#16283F" dark flat>
        <v-tabs align="left" fixed-tabs>
          <v-tab small v-for="tab in tabs" :key="tab" tile text color="secondary" @click="settabvalue(tab)">
            {{ tab }}
          </v-tab>
        </v-tabs>
      </v-toolbar>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  props: {
    tabs: {
      type: Array,
    },
  },

  created() {
    console.log("Tabs", this.tabs)
  },
  methods: {
    settabvalue(tabvalue) {
      this.$emit("settabvalue", tabvalue)
    },
  },
}
</script>
